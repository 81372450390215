import {IOrder} from "../../models/orders/order";
import React, {useState} from "react";
import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import {CURRENCY} from "../../utils/options/options";
import moment from "moment";
import {Link} from "react-router-dom";
import {addNotificationWithButton} from "../../utils/toast/toast";
import {ReactComponent as PaidSVG} from '../../resourse/img/general/ic-paid.svg';
import {ReactComponent as UnpaidSVG} from '../../resourse/img/general/ic-unpaid.svg';
import {TXT} from "../../txtMap/embedded/txt";
import {RouteNames} from "../../migration/pages";

interface IOrderItem {
    order: IOrder
    setView: React.Dispatch<React.SetStateAction<{ order: IOrder | null, active: boolean }>>
    setOrders: React.Dispatch<React.SetStateAction<IOrder[] | null>>
}


export function OrderItem(props: IOrderItem) {
    const {i18n, api} = useGlobalContext()
    const {menu, user, currentMenuId} = useUserContext()

    const [cancelApproveLoading, setCancelApproveLoading] = useState<boolean>(false)
    const [setOrderStatusLoading, setSetOrderStatusLoading] = useState<boolean>(false)

    let paymentMethod = props.order.payment?.method as Exclude<keyof TXT, "runOne">

    function onCancelOrder(id: string) {
        api.CancelOrder({setLoading: setCancelApproveLoading}, currentMenuId, id).then((res) => {
            if (res.success) {
                props.setOrders((orders) => orders ? orders.map((order) => {
                    if (order.id === id) {
                        return {...order, currentStatus: "cancelled"}
                    }
                    return order
                }) : null)
            }
        })
    }

    function onApproveOrder(id: string) {
        api.approveOrder({setLoading: setCancelApproveLoading}, currentMenuId, id).then((res) => {
            if (res.success) {
                props.setOrders((orders) => orders ? orders.map((order) => {
                    if (order.id === id) {
                        return {...order, currentStatus: ""}
                    }
                    return order
                }) : null)
            }
        })
    }

    function onSetOrderStatusApproved(id: string) {
        api.SetManuallyPaymentStatusApproved({setLoading: setSetOrderStatusLoading}, currentMenuId, id).then((res) => {
            if (res.success) {
                props.setOrders((orders) => orders ? orders.map((order) => {
                    if (order.id === id) {
                        return {
                            ...order,
                            payment: order.payment ? {...order.payment, isStatusSetManually: true, status: "approved"} :
                                {isStatusSetManually: true, status: "approved", method: "", completedAt: null}
                        }
                    }
                    return order
                }) : null)
            }
        })
    }

    function onSetOrderStatusCancelled(id: string) {
        api.SetManuallyPaymentStatusCancelled({setLoading: setSetOrderStatusLoading}, currentMenuId, id).then((res) => {
            if (res.success) {
                props.setOrders((orders) => orders ? orders.map((order) => {
                    if (order.id === id) {
                        return {
                            ...order,
                            payment: order.payment ? {
                                    ...order.payment,
                                    isStatusSetManually: true,
                                    status: "cancelled"
                                } :
                                {isStatusSetManually: true, status: "cancelled", method: "", completedAt: null}
                        }
                    }
                    return order
                }) : null)
            }
        })
    }

    return (
        <tr key={props.order.id}
            className={props.order.currentStatus === "cancelled" ? "hidden-item" : ""}>
            <td data-title="№">
                <a href="#/"
                   onClick={() => {
                       props.setView({
                           order: props.order,
                           active: true
                       })
                   }}
                >
                    {props.order.number}
                </a>

            </td>
            <td data-title={i18n.customer()}>
                <span className="text-secondary">{props.order.customerContacts.name}</span><br/>
                <span className="">{props.order.customerContacts.phone.number}</span>
            </td>
            <td data-title={i18n.order_content()}
                className="lg-text-right">
                {
                    props.order.items.map((item, index) => {
                        return (
                            <div style={{display: "flex", justifyContent: "space-between"}}
                                 key={props.order.id + "-" + index.toString()}>
                                <span>{(item.name || {})[menu.i18n.defaultLang]} {item.variant.label ?
                                    item.variant.label[menu.i18n.defaultLang] ?
                                        `(${item.variant.label[menu.i18n.defaultLang]})` : null : null
                                }</span>
                                <span>x {item.quantity}</span>
                            </div>

                        )
                    })
                }
                {props.order.orderSupItems?.map((item, index) => {
                    return (
                        <div key={`${item.id}-${index}-${props.order.iiko?.order.externalNumber}`}>
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                <div>
                                    <span>{item.name[menu.i18n.defaultLang]} </span>
                                </div>
                                <span>x {item.quantity}</span></div>
                        </div>
                    )
                })}
                <br/>
                <span
                    className="font-weight-bold">{Math.ceil(props.order.totalPrice / 100)} {CURRENCY[props.order.currency]} </span>
            </td>
            <td data-title={i18n.delivery_type()}>

                {props.order.delivery.type === "selfPickupEmpty" ? i18n.self_pickup() : null}
                {props.order.delivery.type === "customPlace" ? <>
                    {i18n.at_place()}
                    <div
                        className={'text-secondary'}>{`${props.order.delivery.data?.label
                        ? props.order.delivery.data.label[menu.i18n.defaultLang] || i18n.table_number()
                        : i18n.table_number()}: ${props.order.delivery.data?.value || i18n.empty()}`}
                    </div>
                </> : null}
                {props.order.delivery.type === "city" ? <>
                    {i18n.delivery()}
                    <div className={'text-secondary'}>{props.order.delivery.data?.address}</div>
                    <div className={'text-secondary'}>{props.order.delivery.data?.detailedAddress}</div>
                </> : null}
                {props.order.delivery.type === "placements" ?
                    <>
                        {i18n.in_door_list()}
                        <div
                            className={'text-secondary'}>
                            {`${(props.order.delivery.data?.label || {})[menu.i18n.defaultLang] || i18n.table_number()}: ${(props.order.delivery.data?.name || {})[menu.i18n.defaultLang] || i18n.empty()}`}
                        </div>

                    </>
                    : null}
                {props.order.delivery.type === "cinema" ?
                    <>
                        {i18n.delivery_in_cinema_hall()}
                        <div
                            className={'text-secondary'}>{`${i18n.cinema_hall_number()}: ${props.order.delivery.data?.cinemaHallNumber}`}</div>
                        <div
                            className={'text-secondary'}>{`${i18n.row_number()}: ${props.order.delivery.data?.rowNumber}`}</div>
                        <div
                            className={'text-secondary'}>{`${i18n.seat_number()}: ${props.order.delivery.data?.seatNumber}`}</div>
                    </>
                    : null}
                {props.order.delivery.data?.deliveryComment && props.order.delivery.data?.deliveryComment.length > 0 ?
                    <>
                        <div className="tx-gray">{i18n.delivery_comment()}:</div>
                        <div>{props.order.delivery.data?.deliveryComment}</div>
                    </>
                    : null}
                {props.order.customerComment && props.order.customerComment.length > 0 ?
                    <>
                        <div className="tx-gray">{i18n.customerComment()}:</div>
                        <div>{props.order?.customerComment}</div>
                    </>
                    : null}
            </td>

            <td data-title={i18n.url_parameters()} style={{maxWidth: "400px"}}>

                {Object.keys(props.order.originURLParams || {}).map((key) => {
                    return (
                        <div style={{width: "100%", wordBreak: "break-all",}}>
                            <span style={{color: "orange"}}>{key}</span>
                            <span>{`=${props.order.originURLParams?.[key]}`}</span>
                        </div>
                    )
                })}

            </td>

            <td data-title={i18n.date()}>

                <span className="calendar">{moment(props.order.createdAt).format("DD.MM.YYYY HH:mm")}</span>

            </td>

            {user?.org?.availableModules?.includes("payments") ?
                <td data-title={i18n.status()}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {props.order.payment?.method && <span
                            className={'tags__payment'}
                            style={{backgroundColor: "#c1c1c1"}}>{i18n.runOne(paymentMethod)}</span>}
                        <div className="default-table__status">
                            {props.order.payment?.status === "approved" ?
                                <span
                                    className="tags__payment -paid">{i18n.paid()}</span>
                                :
                                <span
                                    className="tags__payment -not_paid">{i18n.unpaid()}</span>
                            }
                        </div>
                    </div>
                    {props.order.payment?.isStatusSetManually && <><b
                        style={{paddingLeft: "10px"}}>{i18n.changed_manually_short()}</b>
                        <br/></>}
                    {props.order.payment?.completedAt ?
                        <span
                            style={{paddingLeft: "10px"}}>{moment(props.order.payment.completedAt).format("DD.MM.YYYY HH:mm")}</span>
                        : null}

                </td>
                : null}

            {user?.org?.availableModules?.includes("iiko") ?
                <td data-title={i18n.iiko_creation_status()}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {props.order.iikoStatus === "DONE" && <span
                            className={'tags__payment'}
                            style={{backgroundColor: "#1b9956"}}>{i18n.iiko_creation_status_success()}
                        </span>}
                        {props.order.iikoStatus === "ERROR" && <span
                            className={'tags__payment'}
                            style={{backgroundColor: "#ff4136"}}>{i18n.iiko_creation_status_error()}
                        </span>}
                        {props.order.iikoStatus === "IN_PROGRESS" && <span
                            className={'tags__payment'}
                            style={{backgroundColor: "#f2cc56"}}>{i18n.iiko_creation_status_in_progress()}
                        </span>}
                    </div>
                </td>
                : null}

            <td data-title={i18n.actions()}>
                <div className="default-table_actions">
                    {/*<Link to={RouteNames.ORDERS_EDIT.replace(":id", props.order.id)}*/}
                    {/*      className="default-table__button"*/}
                    {/*      title={i18n.edit()}>*/}
                    {/*    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"*/}
                    {/*         xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path*/}
                    {/*            d="M8.6388 1.77197C8.6388 2.08836 8.38237 2.34478 8.06598 2.34478H2.97999C2.03126 2.3459 1.26243 3.11451 1.26154 4.06324V20.1359C1.26243 21.0846 2.03126 21.8532 2.97999 21.8544H17.907C18.8555 21.8532 19.6244 21.0846 19.6255 20.1359V15.0499C19.6255 14.7335 19.8819 14.4771 20.1983 14.4771C20.5145 14.4771 20.7711 14.7335 20.7711 15.0499V20.1359C20.7693 21.717 19.4881 22.9982 17.907 23H2.97976C1.3987 22.9982 0.117466 21.717 0.115677 20.1359V4.06324C0.117466 2.48217 1.3987 1.20094 2.97976 1.19915H8.06598C8.38237 1.19915 8.6388 1.45557 8.6388 1.77197Z"*/}
                    {/*            fill="#8392A5"></path>*/}
                    {/*        <path*/}
                    {/*            d="M22.245 1.42607C23.2517 2.43275 23.2517 4.06483 22.245 5.07151L12.0256 15.2909C11.9555 15.361 11.8687 15.4115 11.7734 15.4379L6.92167 16.7818C6.72275 16.8371 6.50974 16.7809 6.36362 16.635C6.21774 16.4889 6.16157 16.2759 6.21662 16.077L7.56072 11.2253C7.58713 11.13 7.6377 11.0431 7.70773 10.9731L17.9274 0.753901C18.9347 -0.251217 20.5655 -0.251217 21.5728 0.753901L22.245 1.42607ZM11.6532 14.0428L20.0173 5.67901L17.3199 2.98162L8.95585 11.3457L11.6532 14.0428ZM10.572 14.5816L8.41682 12.4266L7.59093 15.4075L10.572 14.5816ZM18.7374 1.5639L18.1299 2.1714L20.8275 4.86901L21.435 4.26129C21.9942 3.70212 21.9942 2.79546 21.435 2.23629L20.7628 1.5639C20.203 1.00563 19.297 1.00563 18.7374 1.5639Z"*/}
                    {/*            fill="#8392A5"></path>*/}
                    {/*    </svg>*/}
                    {/*</Link>*/}

                    <button type="button" className="default-table__button"
                            style={{display: props.order.currentStatus === "cancelled" ? "none" : "block"}}
                            onClick={(e) => {
                                e.preventDefault()
                                addNotificationWithButton({
                                        message: i18n.q_cancel_order(),
                                        onSubmit: () => {
                                            onCancelOrder(props.order.id)
                                        },
                                        loading: cancelApproveLoading,
                                        i18n
                                    }
                                )
                            }}
                            title="Отменить">
                        <svg height="24" width="24" viewBox="0 0 512 512"
                             xmlns="http://www.w3.org/2000/svg" fill="#8392A5">
                            <path
                                d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0"></path>
                            <path
                                d="m176.8125 351.1875c-4.097656 0-8.195312-1.554688-11.308594-4.691406-6.25-6.25-6.25-16.382813 0-22.632813l158.398438-158.402343c6.253906-6.25 16.386718-6.25 22.636718 0s6.25 16.382812 0 22.636718l-158.402343 158.398438c-3.15625 3.136718-7.25 4.691406-11.324219 4.691406zm0 0"></path>
                            <path
                                d="m335.1875 351.1875c-4.09375 0-8.191406-1.554688-11.304688-4.691406l-158.398437-158.378906c-6.253906-6.25-6.253906-16.382813 0-22.632813 6.25-6.253906 16.382813-6.253906 22.632813 0l158.398437 158.398437c6.253906 6.25 6.253906 16.382813 0 22.632813-3.132813 3.117187-7.230469 4.671875-11.328125 4.671875zm0 0"></path>
                        </svg>
                    </button>

                    <button type="button"
                            style={{display: props.order.currentStatus !== "cancelled" ? "none" : "block"}}
                            className="default-table__button"
                            onClick={(e) => {
                                e.preventDefault()
                                addNotificationWithButton({
                                    message: i18n.q_recover_order(),
                                    onSubmit: () => {
                                        onApproveOrder(props.order.id)
                                    },
                                    loading: cancelApproveLoading,
                                    i18n
                                })
                            }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" fill="#8392A5"
                             viewBox="0 0 512 512"
                             xmlSpace="preserve">
                            <path
                                d="M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.331,26.629,74.98,74.98C26.628,123.332,0,187.62,0,256 s26.628,132.667,74.98,181.019C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.981 C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M256,482C131.383,482,30,380.617,30,256S131.383,30,256,30 s226,101.383,226,226S380.617,482,256,482z"/>
                            <path
                                d="M378.305,173.859c-5.857-5.856-15.355-5.856-21.212,0.001L224.634,306.319l-69.727-69.727c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l80.333,80.333c2.929,2.929,6.768,4.393,10.606,4.393 c3.838,0,7.678-1.465,10.606-4.393l143.066-143.066C384.163,189.215,384.163,179.717,378.305,173.859z"/>
                        </svg>
                    </button>
                    {props.order.payment?.status && user?.org?.availableModules?.includes("payments") ?
                        <>

                            {props.order.payment.status !== "approved" ?
                                <button type="button"
                                        className="default-table__button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            addNotificationWithButton({
                                                message: i18n.q_approve_order_payment(),
                                                onSubmit: () => onSetOrderStatusApproved(props.order.id),
                                                loading: setOrderStatusLoading,
                                                i18n
                                            })
                                        }}
                                >
                                    <PaidSVG style={{width: 24, height: "auto"}}/>

                                </button>
                                :
                                <button type="button"
                                        className="default-table__button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            addNotificationWithButton({
                                                message: i18n.q_cancel_order_payment(),
                                                onSubmit: () => onSetOrderStatusCancelled(props.order.id),
                                                loading: setOrderStatusLoading,
                                                i18n
                                            })
                                        }}
                                >
                                    <UnpaidSVG style={{width: 24, height: "auto"}}/>
                                </button>
                            }

                        </>
                        : null}

                </div>
            </td>

        </tr>
    )
}
