import React, {useEffect, useMemo, useState} from "react";
import {LangKey, MyGlobalContext, SSEContext, StaffContext, UserContext} from "../../hooks/globalContext";
import {IStaff} from "../../models/user/staff";
import {IOrganizationName} from "../../models/org/name";
import {IPublishSSEData} from "../../models/sse/publish";
import KamiService from "../../api/kamiService";
import {useNavigate} from "react-router-dom";
import {InitSSE} from "../../hooks/sse";
import {TXT} from "../../txtMap/embedded/txt";
import {useTypedSelector} from "../../migration/shared/lib/hooks/useTypedSelector";
import {useActions} from "../../migration/shared/lib/hooks/useActions";
import {IikoMenuSettings} from "../../migration/entities/iikoMenuSettings";

export interface IGlobalContext {
    children: React.ReactNode
    api: KamiService
    i18n: TXT
    lang: LangKey
    setLang: (lang: LangKey) => void
}

export function GlobalContextProvider({api, lang, setLang, i18n, children}: IGlobalContext) {
    const navigate = useNavigate();

    //================================================================================================
    //============================  USER CONTEXT  ====================================================
    //================================================================================================
    // const [userLoading, setUserLoading] = useState<boolean>(false)
    // const [currentUser, setCurrentUser] = useState<ICurrentUserInfo | null>(null)
    const {
        currentUser,
        isLoadingUser,
        menu,
        currentMenuId,
        isLoadingMenu
    } = useTypedSelector(state => state.user);
    const {
        iikoCompanyId,
        iikoMenuSettings,
        isLoadingGetIikoOrganizations,
        isLoadingGetIikoOrganizationSettings
    } = useTypedSelector(state => state.iiko);
    const {
        setCurrentUser,
        setIikoMenuSettings,
        setIikoCompanyId,
        setIsLoadingUser,
        setMenu,
        setCurrentMenuId,
        setIsLoadingMenu,
        fetchIikoOrganizations,
        fetchIikoOrganizationSettings,
        fetchIikoMenuSettings
    } = useActions();
    // const [iikoCompany, setIikoCompany] = useState<IIKOCompany | null>(null)
    // const [iikoOrg, setIikoOrg] = useState<iikoOrganizationBinding | null>(null)


    const iikoActiveGeneral = useMemo(() => {
        return currentUser?.org?.availableModules?.includes("iiko") || false;
    }, [currentUser?.org])

    const iikoActiveMenu = useMemo(() => {
        if (!iikoMenuSettings?.iikoOrganizationId) {
            return false;
        }
        return currentUser?.org?.availableModules?.includes("iiko") || false;
    }, [currentUser?.org, iikoMenuSettings?.iikoOrganizationId])

    function reloadCurrentUser() {
        api.getCurrentUser({setLoading: setIsLoadingUser}).then((res) => {
            if (res.data) {
                res.data.isStaff = !res.data.org
                setCurrentUser(res.data)
            }
        })
    }

    function setOrgInfo(name: string, subdomain: string, address: string, defaultLang: LangKey, logo: string | null, customDomain: string | null) {
        if (currentUser?.org) {
            setCurrentUser({
                ...currentUser, org: {
                    ...currentUser.org,
                    name: name,
                    logoImg: logo,
                    subdomain: subdomain,
                    address: address,
                    defaultLang: defaultLang,
                    customDomain: customDomain
                }
            })
        }
    }

    //================================================================================================
    //============================  Staff Info  ======================================================
    //================================================================================================
    const [staffInfo, setStaffInfo] = useState<IStaff>({inOrgPage: false, orgIds: []})
    const [orgNamesInfo, setOrgNamesInfo] = useState<IOrganizationName[]>([])
    const [orgLoading, setOrgLoading] = useState<boolean>(false)
    const [orgNamesLoading, setOrgNamesLoading] = useState<boolean>(false)

    function changeOrg(id: string) {
        api.getOrganizationById({setLoading: setOrgLoading}, id).then((res) => {
            if (res.data && currentUser) {
                setCurrentUser({...currentUser, org: res.data})
                let menuId = localStorage.getItem(currentUser.user?.id + "_" + res.data.id) || res.data.menus.defaultId
                setCurrentMenuId(menuId)
            }
        })
    }

    function onStaffPage() {
        if (!currentUser?.org) return
        setCurrentUser({...currentUser, org: null})
        setStaffInfo({...staffInfo, inOrgPage: false})
    }

    function addOrg(orgName: IOrganizationName) {
        if (!staffInfo.orgIds.find((id) => id === orgName.id)) {
            setStaffInfo({inOrgPage: true, orgIds: [orgName.id, ...staffInfo.orgIds].slice(0, 5)})
        }
        if (!orgNamesInfo.find((org) => org.id === orgName.id)) {
            setOrgNamesInfo([...orgNamesInfo, orgName])
        }
        changeOrg(orgName.id)
    }

    function removeOrg(orgId: string) {
        setOrgNamesInfo(orgNamesInfo.filter((org) => org.id !== orgId))
        if (!currentUser?.org) return

        setStaffInfo({inOrgPage: currentUser.org?.id != orgId, orgIds: staffInfo.orgIds.filter((id) => id !== orgId)})
        if (currentUser.org?.id === orgId) {
            setCurrentUser({...currentUser, org: null})
        }
    }

    useEffect(() => {
        if (currentUser?.isStaff) {
            if (!currentUser?.user) return
            let staffInfoL = localStorage.getItem(currentUser.user?.id)
            if (staffInfoL) {
                let res: IStaff = JSON.parse(staffInfoL)
                setStaffInfo(res)
                if (res.orgIds.length > 0 && res.inOrgPage) {
                    changeOrg(res.orgIds[0])
                }
            }
        }
    }, [currentUser?.isStaff])

    useEffect(() => {
        const controller = new AbortController()
        if (currentUser?.isStaff) {
            if (!currentUser?.user) return
            localStorage.setItem(currentUser.user?.id, JSON.stringify(staffInfo))
            let staffInfoL = localStorage.getItem(currentUser.user?.id)
            if (staffInfoL) {
                //get org names
                let info: IStaff = JSON.parse(staffInfoL)
                if (!info.inOrgPage) {
                    setCurrentUser({...currentUser, org: null})
                }
                if (info.inOrgPage && info.orgIds.length != 0 && orgNamesInfo.length == 0) {
                    api.getOrganizationNameByIds({
                        controller,
                        setLoading: setOrgNamesLoading
                    }, info.orgIds).then((res) => {
                        if (res.data) {
                            let orgNames: IOrganizationName[] = []
                            staffInfo.orgIds.forEach((id) => {
                                let org = res.data?.organizationNames.find((o) => o.id === id)
                                if (org) {
                                    orgNames.push(org)
                                }
                            })
                            setOrgNamesInfo(orgNames || [])
                        }
                    })
                }
            } else navigate('/staff/clients')
        }
        return () => controller.abort()
    }, [staffInfo, currentUser?.isStaff])
    //==================================================================================================
    //=======================================Current Menu Info==========================================
    //==================================================================================================
    // const [currentMenuInfo, setCurrentMenuInfo] = useState<ICurrentMenuInfo>({
    //     number: "00000000",
    //     i18n: {
    //         defaultLang: "RU",
    //         otherLangs: [],
    //         currency: "KZT",
    //         timezone: "Asia/Almaty"
    //     }
    // })
    // const [currentMenuId, setCurrentMenuId] = useState<string>("")
    // const [menuInfoLoading, setMenuInfoLoading] = useState<boolean>(false)

    useEffect(() => {
        if (!currentMenuId) {
            return
        }
        const controller = new AbortController()
        api.getCurrentMenuInfo({controller, setLoading: setIsLoadingMenu}, currentMenuId).then((res) => {
            if (res.data) {
                setMenu({i18n: res.data.i18n, number: res.data.number})
                if (res.data.publish) {
                    setMenuPublishSSE({...menuPublishSSE, [res.data.publish.menuId]: res.data.publish})
                }
            }
        })
        return () => {
            controller.abort()
        }
    }, [currentMenuId])
    useEffect(() => {
        if (!currentMenuId) return
        if (!currentUser?.user) return
        if (currentUser?.org) {
            localStorage.setItem(currentUser.user?.id + "_" + currentUser.org?.id, currentMenuId)
        }
    }, [currentMenuId])


    //==================================================================================================
    //=======================================SSE========================================================
    //==================================================================================================
    const [eventSource, setEventSource] = useState<EventSource | null>(null)
    const [menuPublishSSE, setMenuPublishSSE] = useState<IPublishSSEData>({})
    const [lastOrder, setLastOrder] = useState<string | null>(null)
    // useEffect(() => {
    //     if (!currentUser?.user) return
    //     if (!currentUser.org?.id) {
    //         eventSource?.close()
    //         return
    //     }
    //     setEventSource(InitSSE({
    //         url: `${process.env.REACT_APP_API_URL}/sse/org/${currentUser.org?.id}`,
    //         lang: lang,
    //         setLastOrder: setLastOrder,
    //         setCurrentMenuId: setCurrentMenuId,
    //         menuPublishSSE: menuPublishSSE,
    //         setMenuPublishSSE: setMenuPublishSSE,
    //         createdMessage: i18n.new_order(),
    //     }))
    //     return () => {
    //         eventSource?.close()
    //     }
    // }, [currentUser?.org?.id]);

    //==================================================================================================
    //=======================================Iiko========================================================
    //==================================================================================================
    useEffect(() => {
        const controller = new AbortController();
        if (!iikoActiveGeneral) {
            setIikoCompanyId("");
            setIikoMenuSettings({} as IikoMenuSettings);
            return
        }
        if (!currentUser?.org?.id) return
        fetchIikoOrganizationSettings({organizationId: currentUser?.org?.id || ""}, controller, {navigate: navigate});
        return () => controller.abort()
    }, [currentUser?.org?.id, iikoActiveGeneral])

    useEffect(() => {
        if (!iikoActiveGeneral) return;
        const controller = new AbortController();
        fetchIikoOrganizations({organizationId: currentUser?.org?.id || ""}, controller, {navigate: navigate});
        return () => controller.abort()
    }, [iikoCompanyId, currentMenuId, currentUser?.org?.id])

    useEffect(() => {
        if (iikoCompanyId) {
            const controller = new AbortController();
            fetchIikoMenuSettings({menuId: currentMenuId || ""}, controller, {navigate: navigate}, true);
            return () => controller.abort()
        }
    }, [iikoCompanyId, currentMenuId, currentUser?.org?.id])

    //==================================================================================================
    //==================================USER LISTENERS=================================================
    //==================================================================================================
    useEffect(() => {
        if (currentUser?.user) {
            let lang: LangKey = currentUser.user?.defLang as LangKey
            setLang(lang)
        }
    }, [currentUser?.user?.defLang])

    useEffect(() => {
        if (currentUser?.org) {
            let menuId = localStorage.getItem(currentUser.user?.id + "_" + currentUser.org.id) || currentUser.org.menus.defaultId
            if (!(currentUser.org.menus.menus || []).find((menu) => menu.id === menuId)) {
                menuId = currentUser.org.menus.defaultId
            }
            setCurrentMenuId(menuId)
        }
    }, [currentUser])
    return (
        <StaffContext.Provider value={{
            addOrg: addOrg,
            removeOrg: removeOrg,
            changeOrg: changeOrg,
            orgNames: orgNamesInfo,
            onStaffPage: onStaffPage,
        }}>
            <UserContext.Provider value={{
                user: currentUser,
                menu: menu,
                setMenu: setMenu,
                setUser: setCurrentUser,
                setOrgInfo: setOrgInfo,
                reloadCurrentUser: reloadCurrentUser,
                currentMenuId: currentMenuId,
                setCurrentMenuId: setCurrentMenuId,
                iikoCompany: iikoCompanyId,
                setIikoCompany: setIikoCompanyId,
                iikoBindOrg: iikoMenuSettings?.iikoOrganizationId,
                iikoActiveGeneral: iikoActiveGeneral,
                iikoActiveMenu: iikoActiveMenu,
            }}>
                <SSEContext.Provider value={{menuPublishSSE, setMenuPublishSSE}}>
                    <MyGlobalContext.Provider value={{
                        api, setLang, lastOrder,
                        userInfoLoading: isLoadingUser && orgLoading,
                        orgsNameLoading: orgNamesLoading,
                        menusInfoLoading: isLoadingMenu,
                        iikoInfoLoading: isLoadingGetIikoOrganizationSettings || isLoadingGetIikoOrganizations,
                        i18n
                    }}>
                        {children}
                    </MyGlobalContext.Provider>
                </SSEContext.Provider>
            </UserContext.Provider>
        </StaffContext.Provider>
    )
}
