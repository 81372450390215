import {useGlobalContext, useUserContext} from "../../hooks/globalContext";
import React, {useCallback, useEffect, useState} from "react";
import {SubmitButton} from "../input/submitButton";
import {ReactComponent as DeleteSVG} from '../../resourse/img/general/ic-trash.svg';
import {ReactComponent as TurnOnSVG} from '../../resourse/img/general/ic-turn-on.svg';
import {ReactComponent as TurnOffSVG} from '../../resourse/img/general/ic-turn-off.svg';
import {ReactComponent as EditSVG} from '../../resourse/img/general/ic-edit.svg';
import {useSupItems} from "../../hooks/settings/supItems";
import {IOrderSupItem} from "../../models/orderSupItem/orderSupItem";
import {I18nString} from "../../models/lang/i18nString";
import {TextI18n} from "../input/textI18n";
import {NumberInput} from "../input/numberInput";
import {PriceInput} from "../input/priceInput";
import {TBodyWithLoader} from "../loader/tBodyWithLoader";
import {LittleSpinner} from "../loader/littleSpinner";

export function SettingsSupItems() {
    const {i18n} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.sup_items()
    }, [i18n])

    const info = useSupItems()


    return (
        <>
            <div className="default-card">
                <div id="dynamicPart">

                    <div className="default-table my-4">
                        <table>
                            <thead>
                            <tr>
                                <th style={{width: "25%"}}>{i18n.name()}</th>
                                <th style={{width: "25%"}}>{i18n.price()}</th>
                                <th style={{width: "25%"}}>{i18n.min_quantity()}</th>
                                <th style={{width: "25%"}}>{i18n.actions()}</th>
                            </tr>
                            </thead>
                            <TBodyWithLoader rows={3} columns={4} isLoading={info.isLoading}>
                                {info.supItems.map((si, i) => {
                                    return (
                                        <TrSupItem si={si} setNow={info.setNow} reload={info.reload} key={i}/>
                                    )
                                })}
                            </TBodyWithLoader>
                        </table>
                        <div className="form__buttons mt-4">
                            <div className="form__buttons-left">
                                <button className="button -bordered_blue form__button"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            info.setNow({
                                                id: "",
                                                name: {},
                                                price: 0,
                                                menuId: "",
                                                minQuantity: 0,
                                                isDisabled: false
                                            })
                                        }}>
                                    {i18n.add()}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SupItemModal now={info.now} setNow={info.setNow} onCreate={info.onCreate}
                          loading={info.updateLoading}
                          onUpdate={info.onUpdate}/>
        </>

    )
}

interface TrSupItemProps {
    si: IOrderSupItem
    setNow: (now: IOrderSupItem | null) => void
    reload: () => void
}

function TrSupItem({si, setNow, reload}: TrSupItemProps) {
    const {menu} = useUserContext()
    const {i18n, api} = useGlobalContext()
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
    const [enableLoading, setEnableLoading] = useState<boolean>(false)

    function onDelete(id: string) {
        api.deleteOrderSupItem({setLoading: setDeleteLoading}, id).then((res) => {
            if (res.data) {
                reload()
            }
        })
    }

    function onDisable(id: string) {
        api.disableOrderSupItem({setLoading: setEnableLoading}, id).then((res) => {
            if (res.data) {
                reload()
            }
        })
    }

    function onEnable(id: string) {
        api.enableOrderSupItem({setLoading: setEnableLoading}, id).then((res) => {
            if (res.data) {
                reload()
            }
        })
    }

    return (
        <tr>
            <td>
                {si.name[menu.i18n.defaultLang]}
                {si.isDisabled && <span
                    className={"disabled-item-hint"}>{i18n.sh_turned_off()}</span>}
            </td>
            <td>
                {si.price / 100}
            </td>
            <td>
                {si.minQuantity}
            </td>
            <td>
                <EditSVG style={{cursor: "pointer"}} onClick={(e) => {
                    e.preventDefault()
                    setNow(si)
                }}/>
                {deleteLoading ? <LittleSpinner/> :
                    <DeleteSVG style={{cursor: "pointer"}} onClick={(e) => {
                        e.preventDefault()
                        onDelete(si.id)
                    }}/>}
                {enableLoading ? <LittleSpinner/> : si.isDisabled ?
                    <TurnOnSVG style={{cursor: "pointer"}}
                               onClick={(e) => {
                                   e.preventDefault()
                                   onEnable(si.id)
                               }}/> :
                    <TurnOffSVG style={{cursor: "pointer"}}
                                onClick={(e) => {
                                    e.preventDefault()
                                    onDisable(si.id)
                                }}/>}

            </td>
        </tr>
    )
}

interface SupItemModalProps {
    now: IOrderSupItem | null
    setNow: (now: IOrderSupItem | null) => void
    onCreate: (name: I18nString, price: number, minQuantity: number) => void
    onUpdate: (id: string, name: I18nString, price: number, minQuantity: number) => void
    loading: boolean
}

function SupItemModal(props: SupItemModalProps) {
    const {user, menu} = useUserContext()
    const {i18n} = useGlobalContext()

    const onChangeName = useCallback((name: I18nString) => {
        if (props.now) {
            props.setNow({...props.now, name})
        }
    }, [props.setNow, props.now])

    function onChangePrice(price: number) {
        if (props.now) {
            props.setNow({...props.now, price})
        }
    }

    function onChangeMinQuantity(minQuantity: number) {
        if (props.now) {
            props.setNow({...props.now, minQuantity})
        }
    }

    return (
        <>
            <div className={props.now ? "modal modal-preview effect-scale show" : "modal modal-preview effect-scale"}
                 style={{display: props.now ? "block" : "none"}}
                 id="modal-order-preview"
                 aria-hidden={!!props.now}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body px-3 py-4 p-lg-5">
                            <button aria-label="Close" className="close" data-dismiss="modal"
                                    type="button"
                                    onClick={() => {
                                        props.setNow(null)
                                    }}
                            ></button>
                            <div id="order-preview-content">

                                <form className="form">
                                    <div className="lang-tab" style={{display: "block"}}>
                                        <h2 className="tx-semibold mb-4">
                                            <span>{props.now?.id ? i18n.update_sup_items() : i18n.create_sup_items()}</span>
                                        </h2>
                                    </div>

                                    <TextI18n label={i18n.name()} values={props.now?.name || {}}
                                              allLang={menu.i18n.otherLangs || []}
                                              setValues={onChangeName} lang={menu.i18n.defaultLang}
                                              isStaff={!!user?.isStaff}/>
                                    <PriceInput label={i18n.price()} value={props.now?.price || 0}
                                                onChange={onChangePrice}/>
                                    <NumberInput label={i18n.min_quantity()} value={props.now?.minQuantity || 0}
                                                 onChange={onChangeMinQuantity}/>
                                    <div className="form__buttons mt-4">
                                        <div className="form__buttons-left">
                                            <SubmitButton
                                                onSubmit={() => {
                                                    if (props.now) {
                                                        if (props.now.id) {
                                                            props.onUpdate(props.now.id, props.now.name, props.now.price, props.now.minQuantity)
                                                        } else {
                                                            props.onCreate(props.now.name, props.now.price, props.now.minQuantity)
                                                        }
                                                    }
                                                }}
                                                label={i18n.save()} modal={true} disabled={false}
                                                isLoading={props.loading}
                                            />
                                            <button className="button -bordered_blue form__button"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        props.setNow(null)
                                                    }}>
                                                {i18n.cancel()}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={props.now ? "modal-backdrop show" : ""}></div>
        </>
    )
}