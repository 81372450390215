import {Breadcrumbs} from "../../components/breadcrumbs/breadcrumbs";
import {AppHeader} from "../../components/header/appHeader";
import {ILinkArgs} from "../../models/link/link";
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useGlobalContext, useStaffContext, useUserContext} from "../../hooks/globalContext";
import {useClientsInfo} from "../../hooks/staff/staff";
import {IOrganization} from "../../models/organizations/organization";
import moment from "moment/moment";
import {addNotificationWithButton} from "../../utils/toast/toast";
import {Pagination} from "../../components/pagination/pagination";
import {SearchHeader} from "../../components/input/search";
import {TBodyWithLoader} from "../../components/loader/tBodyWithLoader";
import {CustomSkeletonLoader} from "../../components/loader/customSkeletonLoader";
import {LittleSpinner} from "../../components/loader/littleSpinner";
import {RouteNames} from "../../migration/pages";
import {OrgModulesTranslate} from "../../migration/shared/lib/orgModules/orgModulesTranslate";
import {useTypedSelector} from "../../migration/shared/lib/hooks/useTypedSelector";


export function ClientsPage() {
    const info = useClientsInfo()
    const {i18n, userInfoLoading} = useGlobalContext()
    useEffect(() => {
        document.title = i18n.all_organizations()
    }, [i18n])
    let headerInfo: ILinkArgs[] = [
        {
            url: "/staff/clients/create/",
            title: i18n.add_client(),
            svg: `
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.6607 6.16071H9.10714C8.95922 6.16071 8.83929 6.04078 8.83929 5.89286V1.33929C8.83929 0.59967 8.23962 0 7.5 0C6.76038 0 6.16071 0.59967 6.16071 1.33929V5.89286C6.16071 6.04078 6.04078 6.16071 5.89286 6.16071H1.33929C0.59967 6.16071 0 6.76038 0 7.5C0 8.23962 0.59967 8.83929 1.33929 8.83929H5.89286C6.04078 8.83929 6.16071 8.95922 6.16071 9.10714V13.6607C6.16071 14.4003 6.76038 15 7.5 15C8.23962 15 8.83929 14.4003 8.83929 13.6607V9.10714C8.83929 8.95922 8.95922 8.83929 9.10714 8.83929H13.6607C14.4003 8.83929 15 8.23962 15 7.5C15 6.76038 14.4003 6.16071 13.6607 6.16071Z" fill="white"></path>
                </svg>
            `
        }
    ]
    return (
        <section className={userInfoLoading ? "app__main hidden-w-opacity" : "app__main"}>
            <main role="main" className="app__content">
                <Breadcrumbs infos={[{link: "/staff/clients/", title: i18n.clients(), index: "breadcrumbs-id"}]}/>

                <AppHeader links={headerInfo} header={i18n.clients()}/>
                <div className="default-card">

                    <div className="default-card__top-row">
                        <SearchHeader onSubmit={info.handleFind}
                                      label={i18n.search()}/>

                        <div className="results-text d-none d-lg-block d-sm-block">
                            {i18n.all()}:
                            <span className="search-count">{info.count}</span>
                        </div>


                    </div>


                    <div className="default-table">
                        <table id="clients-table">
                            <thead>
                            <tr>
                                <th>{i18n.login()}</th>
                                <th>{i18n.org()}</th>
                                <th>{i18n.creation_date()}</th>
                                <th>{i18n.available_modules()}</th>
                                <th>{i18n.tmp_password()}</th>
                                <th>{i18n.actions()}</th>
                            </tr>
                            </thead>
                            <TBodyWithLoader rows={20} columns={6} isLoading={info.loading}>

                                {info.orgs.map((value, index) =>
                                    <ClientTd user={value} changePass={(v) => info.resetThePassword(v, index)}
                                              enableDisableOrg={info.enableDisableOrg} key={value.id}
                                    />)}

                            </TBodyWithLoader>
                        </table>
                    </div>

                    <Pagination page={info.page} all={info.count} setPage={info.setPage} withdraw={info.step}/>
                </div>

            </main>
        </section>
    )
}

interface IClientTd {
    user: IOrganization,
    changePass: (pass: string) => void

    enableDisableOrg(orgId: string, isDisabled: boolean, setEnableDisableLoading: (loading: boolean) => void): void
}

function ClientTd(props: IClientTd) {
    const navigate = useNavigate();
    const {i18n, api} = useGlobalContext()
    const {addOrg} = useStaffContext()
    const {user} = useUserContext()
    const {currentLang} = useTypedSelector(state => state.lang);

    const [resetPassLoading, setResetPassLoading] = useState(false)
    const [enableDisableLoading, setEnableDisableLoading] = useState(false)

    function onChangePass() {
        addNotificationWithButton({
            message: i18n.q_reset_password(),
            onSubmit: () => {
                api.resetPasswordForUser({setLoading: setResetPassLoading}, props.user?.primaryUserId).then((res) => {
                    if (res.data) {
                        props.changePass(res.data.newTmpPassword)
                    }
                })
            },
            loading: resetPassLoading,
            i18n,
        })
    }

    return (
        <tr className={props.user?.isDisabled ? "archived-user" : ""} id={props.user?.id}>
            <td>
                {props.user?.userLogin}
            </td>
            <td>
                {props.user?.orgName}
            </td>
            <td>

                <span>{moment(props.user?.createdAt).format("DD.MM.YYYY HH:mm")}</span>

            </td>
            <td>
                <ul>
                    {OrgModulesTranslate(props.user?.availableModules || [], currentLang).map((value) => (
                        <li>{value}</li>
                    ))}
                </ul>
            </td>
            <td>
                {resetPassLoading ?
                    <CustomSkeletonLoader/> :
                    <>
                        {props.user?.tmpPswChanged ?
                            <span className="text-success">{i18n.password_changed()}</span>
                            :
                            <span>{props.user?.tmpPassword}</span>
                        }
                    </>
                }

            </td>
            <td>
                <input type="hidden" name="id"/>
                <input type="hidden" name="plNumber" value="777989478256"/>
                <Link to="#" title={i18n.enter_as_client()}
                      onClick={(e) => {
                          e.preventDefault();
                          addOrg({id: props.user?.id, name: props.user?.orgName});
                          navigate(RouteNames.SLIDER)
                      }}
                      className="mr-2"
                >
                    <i className="fa fa-lg fa-user-secret"></i>
                </Link>
                <Link to={"/staff/clients/edit/" + props.user?.id}
                      title={i18n.edit()} className="mr-2">
                    <i className="fa fa-lg fa-pen"></i>
                </Link>
                {user?.user?.isSuper ? props.user?.isDisabled ?
                    <Link to="#" title={i18n.enable_organization()}
                          className="text-success mr-2" onClick={() => {
                        if (enableDisableLoading) return
                        props.enableDisableOrg(props.user?.id, props.user.isDisabled, setEnableDisableLoading)
                    }}>
                        {enableDisableLoading ?
                            <LittleSpinner/>
                            :
                            <i className="fa fa-lg fa-check-circle"></i>
                        }
                    </Link>
                    :
                    <Link to="#" title={i18n.disable_organization()}
                          className="text-danger mr-2" onClick={() => {
                        if (enableDisableLoading) return
                        props.enableDisableOrg(props.user?.id, props.user.isDisabled, setEnableDisableLoading)
                    }}>
                        {enableDisableLoading ?
                            <LittleSpinner/>
                            :
                            <i className="fa fa-lg fa-times-circle"></i>
                        }

                    </Link> : null
                }


                <Link to="#" title={i18n.change_password()}
                      onClick={(e) => {
                          e.preventDefault()
                          onChangePass()
                      }}
                      className="mr-2">
                    {resetPassLoading ?
                        <LittleSpinner/>
                        :
                        <i className="fa fa-lg fa-lock-open"></i>
                    }
                </Link>


                <Link to={`/staff/copy-menu-to-another-org/${props.user?.id}`}
                      title={i18n.copy_menu_to_another_org()}
                      className="mr-2">
                    <i className="fa fa-lg fa-copy"></i>
                </Link>
            </td>
        </tr>
    )
}
